import css from "./Main.module.scss";
import Link from "next/link";
import { Image } from "~/components/Image";
import { useUser } from "context/userContext";
import { useRouter } from "next/router";
import classnames from "classnames";
import { Device } from "~/types/device";
import { ClassroomsPageLink } from "~/components/links/ClassroomsPageLink";
import { ArchivesPageLink } from "~/components/links/ArchivesPageLink";
import { ProfessionalsPageLink } from "~/components/links/ProfessionalsPageLink";
import { NotificationsLink } from "~/components/links/NotificationsLink";

type MainProps = {
  children: any;
  nav?: string;
  only?: Device;
  noMaxWidth?: boolean;
  mainClassName?: string;
  backgroundClassName?: string;
};

const size = {
  width: 24,
  height: 24,
};

export const Main: React.FC<MainProps> = ({ children, nav, only, noMaxWidth, mainClassName, backgroundClassName }) => {
  const { user } = useUser();
  const router = useRouter();
  const navClassName = classnames({
    _only__pc: only === Device.pc,
    _only__sp: only === Device.sp,
  });

  const eventNav = (
    <nav className={navClassName}>
      <div className={css.section}>
        <div className={css.list}>
          <ClassroomsPageLink
            aProps={{
              className: classnames(css.link, {
                [css.active]: router.pathname === "/classrooms",
              }),
            }}
          >
            <span className={css.icon}>
              <Image src="/images/nav__classrooms.svg" {...size} alt="classrooms" />
            </span>
            <span className={css.label}>クラスルーム一覧</span>
          </ClassroomsPageLink>
        </div>
        <div className={css.list}>
          <ArchivesPageLink
            aProps={{
              className: classnames(css.link, {
                [css.active]: router.pathname === "/archives",
              }),
            }}
          >
            <span className={css.icon}>
              <Image src="/images/nav__archive.svg" {...size} alt="archives" />
            </span>
            <span className={css.label}>ライブアーカイブ一覧</span>
          </ArchivesPageLink>
        </div>
        <div className={css.list}>
          <ProfessionalsPageLink
            aProps={{
              className: classnames(css.link, {
                [css.active]: router.pathname === "/professionals",
              }),
            }}
          >
            <span className={css.icon}>
              <Image src="/images/nav__professionals.svg" {...size} alt="professionals" />
            </span>
            <span className={css.label}>プロフェッショナル一覧</span>
          </ProfessionalsPageLink>
        </div>
      </div>
    </nav>
  );

  const signinNav = (
    <nav className={navClassName}>
      <div className={css.section}>
        <div className={css.list}>
          <Link href="/signin" className={classnames(css.link, { [css.active]: router.pathname === "/signin" })}>
            <span className={css.icon}>
              <Image src="/images/nav__identity.svg" {...size} alt="login" />
            </span>
            <span className={css.label}>ログイン</span>
          </Link>
        </div>
        <div className={css.list}>
          <Link href="/signup" className={classnames(css.link, { [css.active]: router.pathname === "/signup" })}>
            <span className={css.button}>新規登録</span>
          </Link>
        </div>
      </div>
    </nav>
  );

  const PolicyNav = (
    <nav className={navClassName}>
      <div className={css.section}>
        <div className={css.list}>
          <Link
            href="/aud/terms_of_use"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/aud/terms_of_use",
            })}
          >
            <span className={css.label}>利用規約（視聴者向け）</span>
          </Link>
        </div>
        <div className={css.list}>
          <Link
            href="/org/terms_of_use"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/org/terms_of_use",
            })}
          >
            <span className={css.label}>利用規約（配信者向け）</span>
          </Link>
        </div>
      </div>
      <div className={css.section}>
        <div className={css.list}>
          <Link
            href="/privacy_policy"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/privacy_policy",
            })}
          >
            <span className={css.label}>プライバシーポリシー</span>
          </Link>
        </div>
        <div className={css.list}>
          <Link
            href="/law"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/law",
            })}
          >
            <span className={css.label}>特定商取引法に基づく表示</span>
          </Link>
        </div>
      </div>
      <div className={css.section}>
        <div className={css.list}>
          <Link
            href="/brand_assets"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/brand_assets",
            })}
          >
            <span className={css.label}>ロゴの利用について</span>
          </Link>
        </div>
      </div>
    </nav>
  );

  const settingsNav = (
    <nav className={navClassName}>
      <div className={css.section}>
        <div className={css.list}>
          <NotificationsLink
            aProps={{
              className: classnames(css.link, {
                [css.active]: router.pathname === "/notifications",
              }),
            }}
          >
            <span className={css.icon}>
              <Image src="/images/_bell.svg" {...size} alt="notifications" />
            </span>
            <span className={css.label}>お知らせ</span>
          </NotificationsLink>
        </div>
        <div className={css.list}>
          <Link
            href="/profile_settings"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/profile_settings",
            })}
          >
            <span className={css.icon}>
              <Image src="/images/nav__settings.svg" {...size} alt="profile setting" />
            </span>
            <span className={css.label}>プロフィール設定</span>
          </Link>
        </div>
        {user?.isProfessional && (
          <div className={css.list}>
            <Link
              href="/org/settings"
              className={classnames(css.link, {
                [css.active]: router.pathname === "/org/settings",
              })}
            >
              <span className={css.label}>プロフェッショナル設定</span>
            </Link>
          </div>
        )}
        <div className={css.list}>
          <Link
            href="/settings"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/settings",
            })}
          >
            <span className={css.label}>アカウント設定</span>
          </Link>
        </div>
      </div>
      <div className={css.section}>
        <div className={css.list}>
          <a href="https://help.nowdo.in" target="_blank" rel="noreferrer" className={css.link}>
            <span className={css.icon}>
              <Image src="/images/nav__help.svg" {...size} alt="help center" />
            </span>
            <span className={css.label}>ヘルプセンター</span>
          </a>
        </div>
      </div>
    </nav>
  );

  const content = (navigation = <></>) => {
    return (
      <div className={classnames([css.paddingTop, backgroundClassName])}>
        <div
          className={classnames([
            css.main,
            {
              [css.mainMaxWidth]: !noMaxWidth,
            },
          ])}
        >
          {navigation}
          <main className={mainClassName}>{children}</main>
        </div>
      </div>
    );
  };

  switch (nav) {
    case "archive":
      return content();
    case "classroom":
      return content(eventNav);
    case "account_settings":
      return content(settingsNav);
    case "signin":
      return content(signinNav);
    case "event":
      return content(eventNav);
    case "policies":
      return content(PolicyNav);
    default:
      return content();
  }
};
