import { Main } from "components/aud/base/Main";
import { Section } from "components/aud/base/Section";

import Head from "next/head";
import { PageTitle } from "components/aud/ui/PageTitle";
import { HomePageLink } from "~/components/links/HomePageLink";

const NotFoundPage = () => (
  <Main>
    <Head>
      <title>404 Not Found</title>
    </Head>
    <Section withInner>
      <PageTitle title="404 Not Found" sub="ページが見つかりませんでした" emoji="_emoji__loupe.svg" />
      <div className="_markdown">
        <p>お探しのページは削除されたか、別のURLに移動しました。</p>
        <p>
          <HomePageLink
            aProps={{
              className: "_button primary",
            }}
          >
            トップページへ戻る
          </HomePageLink>
        </p>
      </div>
    </Section>
  </Main>
);

export default NotFoundPage;
